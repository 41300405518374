
import {throwError as observableThrowError,  Observable, BehaviorSubject, Subject, merge, fromEvent, Observer } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { GlobalState } from "../global.state";
import { GLOBAL_CONSTANT } from './catelog/const/global.constant';
import * as moment from 'moment';
import { params } from "./params";
import { urls } from "./urls";
import * as _ from "lodash";
import { json } from 'd3';
import {environment} from "../../environments/environment";
import {Router, ActivatedRoute} from "@angular/router";
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';


@Injectable()
export class DataService {

    detailPlantId: any;
    CurrPlant: any;
    public sessionObj: any = {};
      public volvoObj: any = {};

    public isMoglixUser: boolean = true;

    //TODO remove and change in get Method
    public isWriteAccess: any = true;

    public buyerPlantId: any = '';
    public lastPlantId: any = '1';

    public companyId: string = '';
    public userId: string = '';
    public userName: string = "";
    public userEmail: string = "";

    superAdminIds: any[] = [];

    public cartCount: any = 0;
    moduleAccessObject: any = '';
    // public currBranch:any;
    public branchNames: any = {};
    // public permissionsMap:any;
    public isSuperUser: boolean = true;
    isSAPintegratedGlobal: boolean = false;
    isCorporateClientGlobal: boolean = false;
    QuoteListCount: any = 0;
    supportEmail: string = "support@moglix.com"
    poEditGlobal : boolean = false ;

    sapTenantId : string = "";
    selectedModule: any = '';
    companySettings: any = {'costFactorEnabled': false , 'orderLimitEnabled': false ,
      'orderUpperLimit': 0.0 , 'orderLowerLimit': 0.0 , 'costCentre': [] , 'nonEditableSp': false};

    poEditSubjectCall = new Subject<any>();
    poEditSubject = this.poEditSubjectCall.asObservable();
    companySettingSubjectCall = new Subject<any>();
    companySettingSubject = this.companySettingSubjectCall.asObservable();

    // CurrPlants:any=[];

    public superUser = {
        "PR": 2, //Purchase Requisition
        "GRN": 2, //Goods Receipt Note
        "BI": 2, //Business Insights
        "INV": 2, //Invoice
        "PMT": 2, //Payments
        "SI": 2, //Store Inventory
        "UM": 2, //User Management
        "SM": 2, //Vendor Management
        "RFQ": 2, //Request For Quotation
        "ASN": 2, // Advance Shipping Note
        "PM": 2, //Product Management
        "GE": 2, //Gate Entry
        "PO": 2 //Purchase Order
    };
    // data stored for supplier
    public _assignedSupplier: any;
    public _freightCharge: any;
    public _laborCharge: any;
    public _packagingCharge: any;
    public _remarks: any;
    public _paymentTerms: any;
    public _paymentValue: any;
    public po: any = {};
    selectedBranchIds: any;
    midMarketFlag = false;

    constructor(public router:Router, public _http: HttpClient, public _state: GlobalState) {
        this._state.subscribe('plantId', (plantId) => {
            this.buyerPlantId = plantId;
        });
        this.superAdminIds = GLOBAL_CONSTANT.superAdminIds;
    }

    public setPo(po: any) {
        this.po = po;
    }

    public getPo() {
        return this.po;
    }

    public getpaymentValue(): any {
        return this._paymentValue;
    }

    public setpaymentValue(value: any) {
        this._paymentValue = value;
    }

    public getpaymentTerms(): any {
        return this._paymentTerms;
    }

    public setpaymentTerms(value: any) {
        this._paymentTerms = value;
    }

    public getremarks(): any {
        return this._remarks;
    }

    public setremarks(value: any) {
        this._remarks = value;
    }

    public getpackagingCharge(): any {
        return this._packagingCharge;
    }

    public setpackagingCharge(value: any) {
        this._packagingCharge = value;
    }

    public getfreightCharge(): any {
        return this._freightCharge;
    }

    public setfreightCharge(value: any) {
        this._freightCharge = value;
    }

    public getassignedSupplier(): any {
        return this._assignedSupplier;
    }

    public setassignedSupplier(value: any) {
        this._assignedSupplier = value;
    }

    public getlaborCharge(): any {
        return this._laborCharge;
    }

    public setlaborCharge(value: any) {
        this._laborCharge = value;
    }

    public getIsSuperUser() {
        return this.isSuperUser;
    }

    public bSubject = new BehaviorSubject(this.isSAPintegratedGlobal);
    public  cSubject = new BehaviorSubject(this.isCorporateClientGlobal);
    public compSubject = new BehaviorSubject(this.companyId);
    public cartSubject = new BehaviorSubject(this.cartCount);
    public QuoteListSubject = new BehaviorSubject(this.QuoteListCount);
    public supportEmailSubject =new BehaviorSubject(this.supportEmail);
    public moduleSubject = new BehaviorSubject(this.selectedModule);
    public moduleAccessSubject = new BehaviorSubject(this.moduleAccessObject);
    // public pSubject = new BehaviorSubject(this.buyerPlantId);
    public pSubjectCall = new Subject<any>();
    pSubject = this.pSubjectCall.asObservable();
    // public currPlantId = new BehaviorSubject(this.CurrPlant);
    // public detailedPlantId = new BehaviorSubject(this.detailPlantId);
    // public currBranchSubject = new BehaviorSubject(this.getBuyerPlantId());

    private logoChangeNotify = new Subject < boolean > ();
    changeNotifyLogo$ = this.logoChangeNotify.asObservable();



    // Service message commands
    announceLogoChange(value: boolean) {
        this.logoChangeNotify.next(value);
    }

    public setIsSapIntg(sapvalue: boolean) {
        this.isSAPintegratedGlobal = sapvalue;
        this.bSubject.next(sapvalue);
        return this.isSAPintegratedGlobal;
        // // console.log("is sap int value changed to ", this.isSAPintegratedGlobal);
    }

    public setPoEditFlag(poEdit: boolean) {
      this.poEditGlobal = poEdit;
      this.poEditSubjectCall.next(poEdit);
    }

    public getPoEditFlag(){
      return this.poEditGlobal;
    }

    public setIsCorporateClient(value: boolean) {
        this.isCorporateClientGlobal = value;
        this.cSubject.next(value);
        return this.isCorporateClientGlobal;
        // // console.log("is corporate value changed to ", this.isCorporateClientGlobal);
    }

    public setSapTenantId(value: string) {
      this.sapTenantId = value;
    }

    public getSapTenantId() {
      return this.sapTenantId;
    }

    public getCorporateClient() {
        return this.isCorporateClientGlobal;
    }

    public getIsMoglixSuperAdmin() {
        let currUserId = this.getUserId().toString();
        // let isSuperAdmin = this.superAdminIds.includes(currUserId);
        // return isSuperAdmin && this.sessionObj['moglixSuperAdmin'];
        return this.sessionObj['moglixSuperAdmin'];
    }

    public getRoleId() {
        return this.sessionObj['roles'][0];
    }
    public getRoleName() {
        return this.sessionObj['roleNames'][0];
    }
    public getAllRoles() {
        return this.sessionObj['roleNames'];
    }
    public isInternalUser() {
        let isInternal = false;
        this.sessionObj["roleNames"].forEach(ids =>{
            if(ids == 'Internal User'){ isInternal =true;}
        });
        return isInternal;
    }
    public getEOCuser()
    {
      let isEOC = false;
      this.sessionObj["roleNames"].forEach( ids=>{
        if(ids == 'EOC User'){ isEOC =true;}
      }
      );
      return isEOC;
    }
    public getAnyAdminuser()
    {
      let isAdmin = false;
      this.sessionObj["roleNames"].forEach( ids=>{
        if(ids == 'Company Super Admin' || ids == 'Branch Super Admin' || ids == 'Moglix Super Admin' || ids=='purchaseAdmin'){ isAdmin =true;}
      }
      );
      return isAdmin;
    }

    public getCurrentPermissions() {
      return this.sessionObj['modulesPermission'];
        // if (!this.isSuperUser) {
        //
        //     // return this.permissionsMap[this.currBranch];
        // }
        // return this.superUser;
    }

    public setPermissions(permissions) {
        this.sessionObj['modulesPermission'] = permissions;
    }

    public getBranchPermissions(branch) {
      return this.sessionObj['modulesPermission'];
        // if (!this.isSuperUser) {
        //
        // }
        // return this.superUser;
    }

    public isPrApprover() {
        if (this.sessionObj['modulesPermission']['PR'] == 3) return true;
        else false;
        // return false;
    }

    public isPoApprover() {
        if (this.sessionObj['modulesPermission']['PO'] == 3) return true;
        else false;
        // return false;
    }

    public getModulePermission(name: string) {
        if (!this.isSuperUser) {
          return this.sessionObj['modulesPermission'][name];
        }
      return this.superUser[name];
    }

    public getMasterDataEditPermission(name: string) {
      return this.sessionObj["modulesPermission"][name] > 1;
    }

    public getAuditTrailAccessPermission(): Observable<any> {
        let url = environment.URLS.AUTH_URL + urls.UM.USERS.GET_AUDIT_TRIAL_ACCESS;
        let body = {
            "idUser": this.getCookie('moglixB2BUserId')
        };
        return this.callRestful('POST', url, body);
    }


    // public getIsWriteAccess(){
    //   return this.isWriteAccess;
    // }

    productListFlag = false;
    public setProductListFlag(flag) {
        this.productListFlag = flag;
    }

    public getProductListFlag() {
        return this.productListFlag;
    }

    public getToken() {
        return this.sessionObj['token'];
    }

    public getUserName() {
        if (this.sessionObj['userName']) {
            this.userName = this.sessionObj['userName'];
        }
        return this.userName;
    }

    public getUserEmail() {
        if (this.sessionObj['userEmail']) {
            this.userEmail = this.sessionObj['userEmail'];
        }
        return this.userEmail;
    }

    public getUserId(): string {
        return this.sessionObj ? this.sessionObj['userId'] : null;
    }

    public getSessionObj(): any {
        return this.sessionObj;
    }

    public getVolvoObj(): any {
        return this.volvoObj;
    }
    public getSessionId(): any {
        return this.sessionObj['sessionId'];
    }
    public setCompanySettings(companySettings: any) {
      this.companySettings = companySettings;
      this.companySettingSubjectCall.next(companySettings);
    }
    public getCompanySettings() {
      return this.companySettings;
    }
    public isAuthenticated(): boolean {
        if (this.sessionObj['userId']) {
            return true;
        }
        return false;
    }

    public getViewSetting() {
        return this.isMoglixUser;
    }

    // ========================================================
    // ============= Company Plant Related functions ==========
    // ========================================================
    public getBranchNames() {
        this.branchNames = this.sessionObj['branchNames'];
        return this.branchNames;
    }

    public getBuyerPlantId(): any {
        return this.buyerPlantId;
    }

    public setSelectedBranchIds(plantId){
        if(plantId === '-1'){
            let plntIds = this.getAllPlantsByCurrCompany(this.companyId)
            this.selectedBranchIds = plntIds.map(Number);
        }else{
            this.selectedBranchIds = [parseInt(plantId)];
        }
        this.setCookie('selectedBranchIds', JSON.stringify(this.selectedBranchIds));
    }

    public setBuyerPlantId(id: any) {
        if(id === '-1') {
            this.lastPlantId = (this.sessionObj['companyToBranch'][this.companyId][0]).toString();
        } else {
            this.lastPlantId = this.buyerPlantId;
        }
        this.setSelectedBranchIds(id);
        this.setCookie('plantId', id, 1);
        this.buyerPlantId = id;
        this.pSubjectCall.next(id);
        //console.log('plantId', id, this.buyerPlantId, typeof this.buyerPlantId, this.lastPlantId, typeof this.lastPlantId);
        return this.buyerPlantId;
    }

    public getCompanyId() {
        //console.log('company id', this.companyId);
        return this.companyId;
    }

    public setCompanyId(id: any) {
        //console.log('company id', id);
        this.setCookie('companyId', id, 1);
        this.companyId = id;
        this.compSubject.next(id);
        return this.companyId;
    }


    // currentPlantId = this.getBuyerPlantId();
    // public setCurrPlantId(id){
    //   this.currentPlantId = id;
    //   return this.currentPlantId = id;
    // }

    public getCurrPlantId(){
      return this.buyerPlantId;
    }

    public getCurrBranch(){
      return this.buyerPlantId;
    }

    // public setCurrBranch(_branch:any){
    //   this.currBranch = _branch;
    // }

    public getBuyerCompanyId(): string {
         return this.getCookie('companyId') ? this.getCookie('companyId') : this.companyId;
    //     if(this.buyerPlantId) {
    //       return this.buyerPlantId != '-1' ? this.sessionObj["branchToCompany"][this.buyerPlantId]: this.sessionObj["branchToCompany"][this.lastPlantId];
    //     } else {
    //       return "0";
    //     }
     }

    public getCompBranch(){
        return this.sessionObj['companyToBranch'];
    }

    public getCurrCompanyID() {
        let companyId = this.getCompanyId();
        if (this.buyerPlantId !== '-1') {
            companyId = this.sessionObj["branchToCompany"][this.buyerPlantId]
        }
        return companyId;
    }

    // public getCurrentBranchName() {
    //     return this.buyerPlantId;
    // }

    public getCurrentCompanyId() {
        return this.sessionObj["branchToCompany"][this.buyerPlantId];
    }

    public getCurrCompanyName() {
        return this.sessionObj["companyNames"][this.getCurrCompanyID()];
    }
    public getCompNameById(compId: any){
        return this.sessionObj["companyNames"][compId];
    }

    public getPlantNameById(plantId: any) {
        return this.sessionObj['branchNames'][plantId];
    }
    // public getAllplantIds(allIds: any): any {
    //     this.AllBuyerPLantId = allIds;
    // }

    public getAllplantIds(): any {
       return this.sessionObj["companyToBranch"][this.buyerPlantId];
    }
    public getAllPlantsByCurrCompany(compId: any){
        let branchIds = this.sessionObj["companyToBranch"][compId];
        let branchIdArr = branchIds ? branchIds.map( x => x.toString()) : [];
        //console.log('branchIdArr', branchIdArr);
        return branchIdArr;
    }
    public getAllPlantIdsUsingHistoryId(): any {
        //console.log('last plant Id', this.lastPlantId);
        return this.sessionObj["companyToBranch"][this.sessionObj["branchToCompany"][this.lastPlantId]];
    }

    public setSessionObjOnce(_sessionObj: any) {
        this.sessionObj = _sessionObj;
    }
    public setCartCount(count) {
        this.cartCount = count;
        this.cartSubject.next(count);
        return this.cartCount;
    }
    public getCartCount() {
        return this.cartCount;
    }
    public setQuoteListCount(count){
        this.QuoteListCount=count;
        this.QuoteListSubject.next(count);
    }
    public getQuoteListCount(){
        return this.QuoteListCount;
    }
    public setSupportEmailId(supportEmail){
        this.supportEmail = supportEmail;
        this.supportEmailSubject.next(supportEmail);
    }
    public getSupportEmailId(){
        return this.supportEmail;
    }
    public setVolvoObj(_volvoObj: any) {
      console.log("OBJECT ", _volvoObj);
      this.setCookie('HOOK_URL', _volvoObj['HOOK_URL'],1);
      this.setCookie('OCI_VERSION', _volvoObj['OCI_VERSION'],1);
      this.setCookie('CART_FORMAT', _volvoObj['CART_FORMAT'],1);
      this.setCookie('BUYER_MPID', _volvoObj['BUYER_MPID'],1);
      this.setCookie('BUYER_USERNAME', _volvoObj['BUYER_USERNAME'],1);
      this.setCookie('ACCOUNT_CODE', _volvoObj['ACCOUNT_CODE'],1);
        this.volvoObj = _volvoObj;
    }


    public setSessionObj(_sessionObj: any) {
        // // console.log("Setting Session", _sessionObj);
        this.isSuperUser = false;
        if (_sessionObj) {
            // this.setCurrBranch(Object.keys(_sessionObj['branchNames'])[0]);
            this.buyerPlantId = Object.keys(_sessionObj['branchNames'])[0];
            this.companyId =  _sessionObj['branchToCompany'][this.buyerPlantId];
            if (this.getCookie('plantId')) {
                this.buyerPlantId = this.getCookie('plantId');
            }
            if (this.getCookie('companyId')) {
                this.companyId = this.getCookie('companyId');
            }
            if (!_sessionObj['moglixSuperAdmin']) {
                // // console.log("Making Permissions");
                // this.makePermisssionsMap(_sessionObj);
            } else {
                // // console.log("Making Super User");
                this.isSuperUser = true;
            }
            this.isMoglixUser = true;
            // if (_sessionObj['companyType'] == "goods") {
            //     this.isMoglixUser = true;
            // } else {
            //     this.isMoglixUser = false;
            // }
        } else {
            // this.setCurrBranch("-1");
            this.buyerPlantId = "-1";
        }
        this.sessionObj = _sessionObj;
    }

    setSelectedModule(module: any) {
        this.selectedModule = module;
        this.moduleSubject.next(module);
        return this.selectedModule;
    }

    getSelectedModule() {
        return this.selectedModule;
    }

    setModuleAccess(modules: any) {
        this.sessionObj['modulesPermission'] = modules;  // added for check session modules
        this.moduleAccessObject = modules;
        this.moduleAccessSubject.next(modules);
        return this.moduleAccessObject;
    }


    public makeBranchData(companyId: any) {
        let companyToBranch = this.getSessionObj()["companyToBranch"];
        let data = companyToBranch[companyId];
        let branchName = this.getBranchNames();
        let compId = companyId;
        var _branchDataset: Array < any > = [];
        for (var i in data) {
            // // console.log(i);a
            var _supplierData: any = {
                id: data[i],
                text: "(" + data[i] + ") " + branchName[data[i]],
                btn: 'Select'
            };
            _branchDataset.push(_supplierData);
        }
        let branchData = _.cloneDeep(_branchDataset);
        // this.CurrPlants = branchData;
        //=======================
        // this.compSubject.next(compId);
        // this.pSubject.next(branchData);
        //=======================
        return branchData;
    }
    // public updateTbldata(plantData) {
    //     this.currPlantId.next(plantData);
    // }
    // public updateProId(plantId) {
    //     this.detailedPlantId.next(plantId);
    // }

    public setDefaultBranchForCompany(companyId: any, branchId: any) {
        if (this.sessionObj["companyToDefaultBranch"]) {
            if (this.sessionObj["companyToDefaultBranch"][companyId]) {
                this.sessionObj["companyToDefaultBranch"][companyId] = branchId;
            }
        }
// <<<<<<< HEAD
    }

    public getDefaultBranchForCompany(companyId: any) {
        if (this.sessionObj["companyToDefaultBranch"]) {
            if (this.sessionObj["companyToDefaultBranch"][companyId]) {
                return this.sessionObj["companyToDefaultBranch"][companyId];
            }
            else {
              return this.sessionObj["companyToBranch"][companyId][0];
            }
//         obj = {
//           "term": t
//         };
//       }
//       arrObj.push(obj);
//     }
//     return arrObj;
//   }

//   // In getMatchElasticQuery we will use match keyword
//   public getMatchElasticQuery(obj){
//     // console.log("GET MONGO:",obj);
//     let q = {
//       "query": {
//         "bool": {
//           "must": []
// >>>>>>> 8b3ac10193593a2dae4ee0e0dc89ee0935f793fc
        }
        return 0;
    }


    // ========================================================
    // ============= Cookies Related functions ================
    // ========================================================
    public getCookie(name: string) {
        return localStorage.getItem(name);
    }
    // public getCookie(name: string) {
    //   //TODO test split and value
    //   let ca: Array<string> = document.cookie.split('; ');
    //   let caLen: number = ca.length;
    //   let cookieName = name + "=";
    //   let c: string;
    //
    //   for (let i: number = 0; i < caLen; i += 1) {
    //     c = ca[i].replace(/^\s\+/g,"");
    //     if (c.indexOf(cookieName) == 0) {
    //       return c.substring(cookieName.length, c.length);
    //     }
    //   }
    //   return "";
    // }
    //
    // public deleteCookie(name) {
    //   this.setCookie(name, "", -1);
    // }
    //
    // public setCookie(name: string, value: string, expireDays: number) {
    //   let d:Date = new Date();
    //   d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
    //   let expires:string = "expires=" + d.toUTCString();
    //   document.cookie = name + "=" + value + "; " + expires + ";";
    // }
    public deleteCookie(name: any) {
        // this.setCookie(name, "", -1);
        localStorage.removeItem(name);
    }

    public setCookie(name: string, value: string, expireDays ? : number) {
        localStorage.setItem(name, value);
    }
    // ========================================================
    // ============== Cookies Related functions  ==============
    // ========================================================



    // ========================================================
    // ======== Reqest Object Creation functions ==============
    // ========================================================
    public convertEStoMongo(esProducts: any[]) {
        return esProducts.map(x => {
            x._source['_id'] = x._source['id'];
            return x._source;
        });
    }

    public getElasticObject(obj) {
        // // console.log("GET MONGO OBJECT:", obj);
        let q = {
            "query": {
                "bool": {
                    "must": this.nestedEsObject(obj)
                }
            },
            "sort": {
                "creationDate": {
                    "order": "desc"
                }
            }
        };
        return q;
    }
    public getElasticObjectV2(obj) {
        // // console.log("GET MONGO OBJECT:", obj);
        let q = {
            "query": {
                "bool": {
                    "must": this.nestedEsObject(obj)
                }
            },
            "sort": {
                "creationDate": {
                    "order": "desc"
                }
            }
        };
        return q;
    }

    nestedEsObject(o, k ? ) {
        let obj = {};
        let arrObj = [];
        for (let key in o) {
            if (o[key] instanceof Array) {
                let t = {};
                t[key] = o[key];
                obj = {
                    "terms": t
                };
            } else if (typeof o[key] == "object") {
                if (k) {
                    obj = {
                        "nested": {
                            "path": k + "." + key,
                            "query": {
                                "bool": {
                                    "must": this.nestedEsObject(o[key], k + "." + key)
                                }
                            }
                        }
                    };
                } else {
                    obj = {
                        "nested": {
                            "path": key,
                            "query": {
                                "bool": {
                                    "must": this.nestedEsObject(o[key], key)
                                }
                            }
                        }
                    };
                }

            } else if (typeof o[key] == "string") {
                let t = {};
                if (k) {
                    t[k + "." + key] = o[key];
                } else {
                    t[key] = o[key];
                }
                // // console.log("FINAL OBJ:", t, key, k);
                obj = {
                    "term": t
                };
            }
            arrObj.push(obj);
        }
        return arrObj;
    }

    // In getMatchElasticQuery we will use match keyword
    public getMatchElasticQuery(obj) {
        // // console.log("GET MONGO:", obj);
        let q = {
            "query": {
                "bool": {
                    "must": []
                }
            },
            "sort": {
                "modificationDate": {
                    "order": "desc"
                }
            }
        };
        for (let key in obj) {
            let t: any = {};
            t[key] = obj[key];
            if (key.split(".").length == 2) {
                let o = {
                    "nested": {
                        "path": key.split(".")[0],
                        "query": {
                            "bool": {
                                "must": [{
                                    "match": t
                                }]
                            }
                        }
                    }
                };
                q.query.bool.must.push(o);
            } else if (key.split(".").length > 2) {
                let o = {
                    "nested": {
                        "path": key.split(".")[0],
                        "query": this.nestedEsQuery(key, obj[key], 1)
                    }
                };
                q.query.bool.must.push(o);
            } else {
                q.query.bool.must.push({
                    "match": t
                });
            }
        }
        // // console.log("QUERY:", q);
        return q;
    }


    public getElasticQuery(obj) {
        // // console.log("GET MONGO:", obj);
        let q = {
            "query": {
                "bool": {
                    "must": []
                }
            },
            "sort": {
                "modificationDate": {
                    "order": "desc"
                }
            }
        };
        for (let key in obj) {
            let t: any = {};
            t[key] = obj[key];
            if (key.split(".").length == 2) {
                let o = {
                    "nested": {
                        "path": key.split(".")[0],
                        "query": {
                            "bool": {
                                "must": [{
                                    "term": t
                                }]
                            }
                        }
                    }
                };
                q.query.bool.must.push(o);
            } else if (key.split(".").length > 2) {
                let o = {
                    "nested": {
                        "path": key.split(".")[0],
                        "query": this.nestedEsQuery(key, obj[key], 1)
                    }
                };
                q.query.bool.must.push(o);
            } else {
                // if(this.isCorporateClientGlobal == true)
                //   q.query.bool.must.push({"terms": t});
                // else
                // let queryObj = {}
                // if(key=="plantId"){
                //   queryObj = {"term":t};
                // } else {
                //   queryObj = {"match":t};
                // }
                // q.query.bool.must.push(queryObj);
                //
                q.query.bool.must.push({
                    "term": t
                });
            }
        }
        // // console.log("QUERY:", q);
        return q;
    }

    nestedEsQuery(query: any, value: any, i: number) {
        let obj = {};
        if (query.split(".").length > i + 1) {
            obj = {
                "bool": {
                    "must": [{
                        "nested": {
                            "path": query.split(".").splice(0, i + 1).join("."),
                            "query": this.nestedEsQuery(query, value, i + 1)
                        }
                    }]
                }
            };
        } else {
            let t = {};
            t[query] = value;
            obj = {
                "term": t
            };
        }

        return obj;
    }
    // ========================================================
    // ======== Reqest Object Creation functions end ==========
    // ========================================================


    // ========================================================
    // =========== Rest API Calling Functions =================
    // ========================================================
    callRestful(type: string, url: string, body ? : any, isText ? : boolean) {
        let headers = new HttpHeaders({
            //'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
            'source': "BUYERS",
            'idUser': this.getCookie('moglixB2BUserId') ? this.getCookie('moglixB2BUserId').toString() : '',
            'token': this.getCookie('moglixB2BToken') ? this.getCookie('moglixB2BToken').toString() : '',
            'idBranch': this.getBranchIdForHeaders(this.getBuyerPlantId()) ? this.getBranchIdForHeaders(this.getBuyerPlantId()).toString() : '',
            'idCompany': this.getCompanyId() ? this.getCompanyId().toString() : this.getBuyerCompanyId() ? this.getBuyerCompanyId().toString(): '',
            'application': '1'
        });

        let options =  {headers: headers};
        if(isText){
            options = {...options, ...{'responseType': 'text' as const}}
        }

        switch (type) {
            case 'GET':
                return this._http.get<any>(url, options).pipe(map(res => res));
            case 'POST':
                return this._http.post<any>(url, body, options);
            case 'POST_ERROR':
                return this._http.post<any>(url, body, options);
            case 'PUT':
                return this._http.put<any>(url, body, options);
            case 'DELETE':
                return this._http.delete<any>(url, options);
            default:
                // // console.log('Debug at type request ' + type);
                return null; 
        }
    }

    callRestfulForFile(type: string, url: string, body ? : any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
            'source': "BUYERS",
            'idUser': this.getCookie('moglixB2BUserId') ? this.getCookie('moglixB2BUserId').toString() : '',
            'token': this.getCookie('moglixB2BToken') ? this.getCookie('moglixB2BToken').toString() : '',
            'idBranch': this.getBranchIdForHeaders(this.getBuyerPlantId()) ? this.getBranchIdForHeaders(this.getBuyerPlantId()).toString() : '',
            'idCompany': this.getCompanyId() ? this.getCompanyId().toString() : this.getBuyerCompanyId() ? this.getBuyerCompanyId().toString() : '',
            'application': '1'
        });


        let options =  {headers: headers, 
            // responseType: ResponseContentType.Blob
        };


        switch (type) {
            case 'GET':
                return this._http.get<any>(url, options).pipe(map(res => res));
            case 'POST':
                return this._http.post<any>(url, body, options);
            case 'POST_ERROR':
                return this._http.post<any>(url, body, options);
            case 'PUT':
                return this._http.put<any>(url, body, options);
            case 'DELETE':
                return this._http.delete<any>(url, options);
            default:
                // // console.log('Debug at type request ' + type);
                return null;
        }
    }


    callRestfulForEMS(type: string, url: string, body ? : any) {
        let headers = new HttpHeaders({
            // 'Content-Type': 'application/json',
            // 'Access-Control-Allow-Origin': '*',
            // 'Access-Control-Allow-Credentials': true,
            // 'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
            // 'Cache-Control': 'no-cache',
            // 'Access-Control-Request-Headers':' access-control-allow-credentials,accesskey,applicationname,cache-control',
            // 'Postman-Token': 'c113dff9-4734-4e1b-bb4e-76d2aa5ce881',
            // 'idUser': this.getCookie('moglixB2BUserId'),
            // 'token': this.getCookie('moglixB2BToken'),
            // 'idBranch': this.getBranchIdForHeaders(this.getBuyerPlantId()),
            // 'idCompany': this.getCompanyId() ? this.getCompanyId() : this.getBuyerCompanyId(),
            // 'application': 1,
            'accessKey': environment.URLS.EMS_API2_ACCESS_KEY,
            'applicationName' : 'EMS'
        });

        let options =  {headers: headers, 
            // responseType: ResponseContentType.Blob
        };

        switch (type) {
            case 'GET':
                return this._http.get<any>(url, options).pipe(map(res => res));
            case 'POST':
                return this._http.post<any>(url, body, options);
            case 'POST_ERROR':
                return this._http.post<any>(url, body, options);
            case 'PUT':
                return this._http.put<any>(url, body, options);
            case 'DELETE':
                return this._http.delete<any>(url, options);
            default:
                return null;
        }
    }

    callRestfulForAccount(type: string, url: string, body ? : any) {
        let headers = new HttpHeaders({
            //'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
            'source': "BUYERS",
            'idUser': this.getCookie('moglixB2BUserId') ? this.getCookie('moglixB2BUserId').toString() : '',
            'token': this.getCookie('moglixB2BToken') ? this.getCookie('moglixB2BToken').toString() : '',
            'idBranch': this.getBranchIdForHeaders(this.getBuyerPlantId()) ? this.getBranchIdForHeaders(this.getBuyerPlantId()).toString() : '',
            'idCompany': this.getCompanyId() ? this.getCompanyId().toString() : this.getBuyerCompanyId() ? this.getBuyerCompanyId().toString(): '',
            'application': '1',
            'Authorization': `Bearer ${environment.URLS.CENTRAL_APITOKEN}`,
        });
        
        let options =  {headers: headers};
        
        switch (type) {
            case 'GET':
                return this._http.get<any>(url, options).pipe(map(res => res));
            case 'POST':
                return this._http.post<any>(url, body, options);
            case 'POST_ERROR':
                return this._http.post<any>(url, body, options);
            case 'PUT':
                return this._http.put<any>(url, body, options);
            case 'DELETE':
                return this._http.delete<any>(url, options);
            default:
                return null;
        }
    }

    callRestfulForEmsInventory(type: string, url: string, body ? : any) {
        let headers = new HttpHeaders({
            'Authorization': `Bearer ${environment.URLS.EMS_WMS_TOKEN}`,
            'Content-Type' : 'application/json'
        });

        let options = { headers: headers }

        switch (type) {
            case 'GET':
                return this._http.get<any>(url, options).pipe(map(res => res));
            case 'POST':
                return this._http.post<any>(url, body, options);
            case 'POST_ERROR':
                return this._http.post<any>(url, body, options);
            case 'PUT':
                return this._http.put<any>(url, body, options);
            case 'DELETE':
                return this._http.delete<any>(url, options);
            default:
                return null;
        }
    }

    callRestfulEbd(type: string, url: string, body ? : any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
            // 'OCI_VERSION': this.getCookie('OCI_VERSION'),
            // 'CART-FORMAT':this.getCookie('CART-FORMAT'),
            // 'BUYER_MPID': this.getCookie('BUYER_MPID'),
            // 'BUYER_ORGANIZATION': this.getCookie('BUYER_ORGANIZATION'),
            // 'TOP_ORGANIZATION': this.getCookie('TOP_ORGANIZATION'),
            // 'LANGUAGE': this.getCookie('LANGUAGE'),
            // 'BUYER_USERNAME':this.getCookie('BUYER_USERNAME'),
            // 'ACCOUNT_CODE':this.getCookie('ACCOUNT_CODE')
        });

        let options = { headers: headers }

        switch (type) {
            case 'GET':
                return this._http.get<any>(url, options).pipe(map(res => res));
            case 'POST':
                return this._http.post<any>(url, body, options);
            case 'POST_ERROR':
                return this._http.post<any>(url, body, options);
            case 'PUT':
                return this._http.put<any>(url, body, options);
            case 'DELETE':
                return this._http.delete<any>(url, options);
            default:
                // // console.log('Debug at type request ' + type);
                return null;
        }
    }


    callRestfulWithoutHeaders(type: string, url: string, body ?: any){
        switch (type) {
            case 'GET':
                return this._http.get<any>(url).pipe(map(res => res));
            case 'POST':
                return this._http.post<any>(url, body);
            case 'POST_ERROR':
                return this._http.post<any>(url, body);
            case 'PUT':
                return this._http.put<any>(url, body);
            case 'DELETE':
                return this._http.delete<any>(url);
            default:
                // // console.log('Debug at type request ' + type);
                return null;
        }
    }

    callRestfulForCatalog(type: string, url: string, options?: { params?: {}, body?: {} }) {
        // // console.log(type, ' ', url);
        let requestOptionArgs = {};
        let searchParams;
        let body;

        if (options != undefined && options['params'] != undefined)
            searchParams = options['params'];
        if (options != undefined && options['body'] != undefined)
            body = options['body'];

        let headerData = {
            'Content-Type': 'application/json',
            //'Content-Type': 'application/x-www-form-urlencoded',
            /*'Access-Control-Allow-Origin':'*',
             'Access-Control-Allow-Credentials':true,*/
            'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE'
        };
        let headers = new HttpHeaders(headerData);

        requestOptionArgs["headers"] = headers;
        let params = {}
        if (searchParams != undefined && Object.keys(searchParams).length > 0) {
            let urlSearchParams = new URLSearchParams();
            for (let key in searchParams) {
                urlSearchParams.set(key, searchParams[key]);
            }
            params = searchParams;
            requestOptionArgs["search"] = urlSearchParams;
        }

        if (body != undefined && Object.keys(body).length > 0) {
            requestOptionArgs["body"] = body;
        }

        let requestOptions = requestOptionArgs;

        switch (type) {
            case 'GET':
                return this._http.get<any>(url, { params: params }).pipe(map(res => {
                    return res;
                }));
            case 'POST':
                return this._http.post<any>(url, body, requestOptions).pipe(map(res => res));
            case 'PUT':
                return this._http.put<any>(url, body, requestOptions).pipe(map(res => res));
            case 'DELETE':
                return this._http.delete<any>(url, requestOptions).pipe(map(res => res));
            default:
                //// console.log('Debug at type request ' + type);
                return null;
        }
    }
    getBranchIdForHeaders(plantId: any) {
      if ( plantId === '-1') {
        return this.sessionObj && this.sessionObj['companyToBranch'] ? this.sessionObj['companyToBranch'][this.getCompanyId()] : null;
      } else {
        return plantId;
      }
    }

// ===========Calling Restful for GSTN=================================
    callRestfulForGstn(url: string, body?: any) {
        const headers = new HttpHeaders({
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json',
            'Postman-Token': 'f524b4c0-7c5b-4907-9822-1e937c00ec3d'
        });

        const options = {headers: headers};
        return this._http.post<any>(url, body, options);
    }

// ============Calling Restful for CustomerPlantCode======================
    callRestfulForPlantandVendorMapping(url: string, body?: any) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'POST'
       });
        const options = {headers: headers};
        return this._http.post<any>(url, body, options);
    }
    fileUpload(url: string, body ? : any) {
        let headers = new HttpHeaders({
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
            'source': "BUYERS",
            'idUser': this.getCookie('moglixB2BUserId') ? this.getCookie('moglixB2BUserId').toString() : '',
            'token': this.getCookie('moglixB2BToken') ? this.getCookie('moglixB2BToken').toString() : '',
            'idBranch': (this.getBuyerPlantId() !== '-1') ? this.buyerPlantId.toString() : this.getAllPlantsByCurrCompany(this.companyId).toString(),
            'idCompany': this.companyId.toString(),
            'application': '1'
        });

        let options = { headers: headers }

        return this._http.post<any>(url, body, options);
    }

    uploadFile(url: string, file: File) {

        let formData:FormData = new FormData();
        formData.append('uploadFile', file, file.name);
        let headers = new HttpHeaders({
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
            'idUser': this.getCookie('moglixB2BUserId') ? this.getCookie('moglixB2BUserId').toString() : '',
            'token': this.getCookie('moglixB2BToken') ? this.getCookie('moglixB2BToken').toString() : '',
            'idBranch': this.getBuyerPlantId() ? this.getBuyerPlantId().toString() : '',
            'idCompany': this.companyId ? this.companyId.toString() : '',
            'application': '1'
        });
        let options = { headers: headers }
        return this._http.post<any>(url, formData, options);
      }

      setMidMarketFlag(midMarketFlag: any) {
          this.midMarketFlag = midMarketFlag;
      }

      getMidMarketFlag() {
          return this.midMarketFlag;
      }

      public handleError(error: Response | any, isNotError ? : boolean) {
        console.log("erorrhandler", error);
        if (error.url.indexOf('/user/getSession') !== -1 && error.status == 400) {
            this.showMessage("error", "Session expired. Please login again. Redirecting...");
            this.router.navigateByUrl('/login');
        }

        if (!isNotError && !error.ok) {
            $('#loader').css('display', 'none');
            this.showMessage("error", "Internal Sever Error Occured (" + error.status + "). Please refresh the page.");
            //this.logError(error);
        }
        let errMsg: string;
        if (error instanceof HttpErrorResponse) {
            if (error.error && error.error.status_code == 400) {
                const err = JSON.stringify(error.error);
                errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
            } else {
                const err = error || JSON.stringify(error);
                errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
            } 
        } else {
            errMsg = error.message ? error.message : error.toString();
        }    
        return observableThrowError(errMsg);    
    }

    logError(error: any) {
        // // console.log("in log error", error);
        let url = environment.URLS.PROCUREMENT_URL + urls.ERROR.LOG_ERROR;
        let body = {
            "errorBody": error,
            "sessionObj": this.sessionObj
        };
        this.callRestful('POST_ERROR', url, body).subscribe((response) => {
            $('#loader').css('display', 'none');
            // // console.log("error api response", response);
        });
    }
    // ========================================================
    // =========== Rest API Calling Functions end =============
    // ========================================================


    // ================================================
    // ========= TimeStamp Related Functions ==========
    // ================================================
    public timestampToData(ts) {
        let d = new Date(ts);
        let dd: any = d.getDate();
        let mm: any = (d.getMonth() + 1);
        let yy = d.getFullYear();

        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        return dd + '-' + mm + '-' + yy;
    }

    public getTimeFromTimestamp(ts, isNoTime ? : boolean) {
        ts = ts.toString().length <= 10 ? ts*1000 : ts;
        let d = new Date(ts);
        let dateFormatString = "DD/MM/YYYY hh:mm a";
        if (isNoTime) dateFormatString = "DD/MM/YYYY";
        let dateString = moment(d).format(dateFormatString);
        return dateString;
    }
    // ================================================
    // ========= TimeStamp Related Functions End ======
    // ================================================


    // ====================================================
    // ===== Function to show Error & Success Message =====
    // ====================================================
    showMessage(cssClass, msg, time?: any) {
        // // console.log("show message cssClass " + cssClass + " msg is " + msg);
        let timeout = time ? time * 1000 : 3000;
        const x = document.getElementById('mogb_alertbox');
        let classType = '';
        x.innerHTML = msg;
        if (cssClass === 'error') {
            classType = 'mogb_alertbox error';
        }
        if (cssClass === 'success') {
            classType = 'mogb_alertbox success';
        }
        x.className = classType;
        setTimeout(function() {
            x.className = x.className.replace(classType, 'mogb_alertbox');
        }, timeout);
    }

    hideMessage(){
        document.getElementById("mogb_alertbox").className = "mogb_alertbox";
    }

    removeAllPunchOutCookies() {
        localStorage.removeItem("PUNCHOUT_DOMAIN");
        localStorage.removeItem("PUNCHOUT_SECRET");
        localStorage.removeItem("BUYER_COOKIE");
        localStorage.removeItem("HOOK_URL");
        localStorage.removeItem("PUNCHOUT_IDENTITY");
        localStorage.removeItem("PUNCHOUT_USERAGENT");
        localStorage.removeItem('OCI_VERSION');
        localStorage.removeItem('CART_FORMAT');
        localStorage.removeItem('BUYER_MPID');
        localStorage.removeItem('BUYER_USERNAME');
        localStorage.removeItem('ACCOUNT_CODE');
    }

    removeLocalStorage(){
        this.deleteCookie('moglixB2BUserId');
        this.deleteCookie('moglixB2BToken');
        this.deleteCookie('plantId');
        this.deleteCookie('selectedBranchIds');
        this.deleteCookie('companyId');
        this.deleteCookie('userEmailId');
        this.deleteCookie('userName');
        this.deleteCookie('phoneNo');
        this.deleteCookie('prevUmFlag');
        this.deleteCookie('umFlag');
        this.deleteCookie('selectedBranchIds');
        this.deleteCookie('sessionId');
        localStorage.clear();
    }

    createOnline$() {
    return merge<boolean>(
        fromEvent(window, 'offline').pipe(map(() => false)),
        fromEvent(window, 'online').pipe(map(() => true)),
        new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
        }));
    }
    trackUserDetails(eventType :string, eventData :any,eventSource :string){
        const req = {
            userId: localStorage.getItem('userName'),
            userEmail: localStorage.getItem('userEmailId'),
            eventModules: 'BUYERS',
            eventData: eventData || {},
            eventType: eventType,
            eventSource: eventSource || '',
            eventSessionId: localStorage.getItem('sessionId')
        }
        this.callRestful("POST", environment.URLS.EOC_API + 'userActivitiesTracker', req)
        .subscribe();
    }
    
}